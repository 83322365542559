import { apiInstance,  } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const exibirCarta = async (id) => {
  const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_CARTA + `/${id}`);
  return data;
};

export const convertEmModeloCarta = async (id) => {
  const { data } = await apiInstance.post(ENDPOINTS.CONVERTER_EM_MODELO_CARTA + `/${id}`);
  return data;
};

export const buscarCarta = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_CARTA, payload);
  return data;
};

export const criarCarta = async (payload) => {
  const { data } = await apiInstance.post(ENDPOINTS.CRIAR_CARTA, payload);
  return data;
};

export const editarCarta = async (id, payload) => {
  const { data } = await apiInstance.put(ENDPOINTS.EDITAR_CARTA + `/${id}`, payload);
  return data;
};

export const previewPdfCarta = async (id) => {
  const { data } = await apiInstance.put(ENDPOINTS.PREVIEW_PDF_CARTA + `/${id}`);
  return data;
};