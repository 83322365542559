<template>
  <v-card elevation="1" class="comentarios">
    <div class="pa-4 grey lighten-3 font-weight-bold">Comentários</div>
    <hr />
    <div class="body pa-4" id="comentario-box">
      <div v-for="(comentario, idx) of comentarios" :key="idx">
        <div
          :class="[
            comentario.funcionariosId === $store.state.user.data.id
              ? 'text-right ml-8'
              : 'mr-8',
            'comentario',
          ]"
        >
          <div class="grey lighten-3 pa-2">
            <span class="mb-0 font-weight-bold">{{
              comentario.nomeFuncinario
            }}</span>
            <v-icon
              v-if="comentario.bloqueada === 0 && comentario.funcionariosId === $store.state.user.data.id"
              color="red"
              small
              class="ml-2"
              @click="deletar(comentario)"
              >mdi-delete-outline</v-icon
            >
            <p class="caption mb-0">
              {{ comentario.createdAt | dataTimePtBr }}
            </p>
            <span class="caption" v-if="comentario.visivelApp === 1">
              - Visível ao representante
            </span>
          </div>
          <p class="pa-2 grey lighten-4" v-html="comentario.comentario"></p>
        </div>
      </div>
    </div>
    <div class="grey lighten-5" v-if="$route.name !== 'SolicitacaoVisualizarAtendimento'">
      <hr />
      <v-form class="pa-4" ref="form" v-model="valid" lazy-validation>
        <v-textarea
          outlined
          placeholder="Inserir comentário"
          v-model="formData.comentario"
          rows="2"
          class="white"
          required
          :rules="rules.genericRules"
        ></v-textarea>
        <v-switch
          v-model="formData.visivelApp"
          label="Visível ao representante?"
          inset
          :hide-details="true"
          class="mt-0 pa-0 mb-4"
          color="cyan"
        ></v-switch>
        <v-btn
          small
          depressed
          color="fmq_gray"
          dark
          class="mt-4"
          @click="send"
          :loading="loading"
          block
        >
          Adicionar Comentário
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { rules } from "@/utils/rules.js";
import moment from "moment";
export default {
  name: "SolicitacoesComentarios",
  props: {
    comentarios: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formData: {
      comentario: null,
      visivelApp: false,
    },
    rules: rules,
    valid: false,
  }),
  mounted() {
    this.scrollToBottom()
  },
  filters: {
    dataTimePtBr: function (value) {
      if (value) {
        return moment(value).format("DD/MM/Y HH[h]:mm[m]");
      } else {
        return null;
      }
    },
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    send() {
      if (this.formValid) this.$emit("send", this.formData);
    },
    deletar(item) {
      this.$emit("deletar", item.id);
    },
    scrollToBottom () {
      const element = document.getElementById('comentario-box')
        element.scrollTop = element.scrollHeight
    },
  },
  computed: {
    formValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.comentarios .body {
  height: 100%;
  max-height: 507px;
  overflow-y: scroll;
}
.comentarios .body::-webkit-scrollbar {
  width: 8px;
}
.comentarios .body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.comentarios .body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}
.comentarios .body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.comentario div {
  border-radius: 6px 6px 0px 0px;
}
.comentario p {
  border-radius: 0px 0px 6px 6px;
}
</style>
